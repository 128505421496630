<template>
  <div
    class="list-detail-container d-flex flex-column scroll-container white rounded-md"
    v-if="list"
  >
    <h2 class="primary--text font-weight-bold pl-3 pl-sm-0 py-3 py-sm-0">
      {{ list.name }}
    </h2>

    <div
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length > 0"
    >
      <v-row
        no-gutters
        class="text-caption font-weight-bold mb-3"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-col cols="6" class="">
          {{ $t("lists.header.product") }}
        </v-col>
        <v-col cols="3" class="text-center">
          {{ $t("lists.header.unitPrice") }}
        </v-col>
        <v-col cols="2" class="text-center">
          {{ $t("lists.header.qty") }}
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>

      <v-list class="t-detail-list" v-if="list && !showSectors">
        <ListItem
          v-for="item in list.wishlistItems"
          v-bind:key="item.itemId"
          :item="item"
          :isActive="true"
          @removeItem="removeFromList"
          @update="updateList"
        ></ListItem>
        <ListItem
          v-for="item in list.wishlistInactiveItems"
          v-bind:key="item.itemId"
          :item="item"
          @removeItem="removeFromList"
          :isActive="false"
        ></ListItem>
      </v-list>

      <v-expansion-panels
        v-if="itemGroups.size && showSectors"
        v-model="panel"
        accordion
        flat
        multiple
      >
        <v-expansion-panel
          v-for="([categoryId, group], index) in itemGroups"
          v-bind:key="categoryId"
        >
          <v-expansion-panel-header
            v-on:click.prevent="() => {}"
            class="text-caption primary--text grey lighten-2 px-3"
          >
            <v-row no-gutters>
              <span class="font-weight-bold mr-2">{{ group.name }}</span>
              <span>{{
                `${$tc("lists.products", group.products.length)}`
              }}</span>
            </v-row>
            <template v-slot:actions>
              <v-icon color="primary" x-small>
                {{ panel.includes(index) ? "$minus" : "$plus" }}
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ListItem
              v-for="item in group.products"
              v-bind:key="item.itemId"
              :item="item"
              :isActive="item.isActive"
              @removeItem="removeFromList"
              @update="updateList"
            ></ListItem>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <v-card
      light
      depresses
      elevation="0"
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length == 0"
      class="px-3"
    >
      <v-card-title class="px-0 py-4 font-weight-bold">{{
        $t("lists.noProducts")
      }}</v-card-title>
    </v-card>
    <v-row no-gutters class="justify-space-between mt-6 mb-10 px-3">
      <v-btn
        v-if="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        color="primary"
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        :to="{ name: 'Home' }"
      >
        {{ $t("lists.button.startShopping") }}
      </v-btn>
      <v-btn
        outlined
        color="primary"
        class="bg-white mt-3 mt-md-0"
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        :disabled="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
        @click="emptyList()"
      >
        {{ $t("lists.button.emptyList") }}
      </v-btn>
      <v-btn
        color="primary"
        class="mt-3 mt-md-0"
        :block="$vuetify.breakpoint.smAndDown"
        depressed
        @click="addAllToCart"
        :disabled="
          list.wishlistItems.length + list.wishlistInactiveItems.length == 0
        "
      >
        {{ $t("lists.button.addAllToCart") }}
      </v-btn>
    </v-row>
  </div>
</template>
<style lang="scss">
.list-detail-container {
  .v-expansion-panel {
    border-radius: 0;
    .v-expansion-panel-header {
      min-height: 20px;
      padding: 10px 24px;
    }
    .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 0px !important;
      .v-list-item {
        padding: 0;
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          border-bottom: 1px solid $gray-border-color;
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
<script>
import ListItem from "@/components/lists/ListItem.vue";

import { mapActions } from "vuex";

import deliveryReactive from "~/mixins/deliveryReactive";

import ListService from "~/service/listService";

import map from "lodash/map";
import concat from "lodash/concat";
import groupBy from "lodash/groupBy";
import AnalyticsService from "~/service/analyticsService";

export default {
  name: "ListDetail",
  props: {
    listId: { type: Number, required: true },
    showSectors: { type: Boolean, default: false }
  },
  data() {
    return {
      list: null,
      panel: []
    };
  },
  components: {
    ListItem
  },
  mixins: [deliveryReactive],
  computed: {
    itemGroups() {
      let groups = new Map();
      // if( this.list)
      this.list?.wishlistItems.forEach(item => {
        item.isActive = true;
      });
      this.list?.wishlistInactiveItems.forEach(item => {
        item.isActive = false;
      });
      let allWishlistProducts = [
        ...this.list?.wishlistItems,
        ...this.list?.wishlistInactiveItems
      ];
      allWishlistProducts.forEach(item => {
        let category = this.$store.getters["category/lookupCategory"](
          item.product.categoryId
        );
        if (category === null || category === undefined) {
          category = {
            categoryId: -1,
            name: "Senza categoria"
          };
        }
        if (groups.has(category.categoryId)) {
          groups.get(category.categoryId).products.push(item);
        } else {
          groups.set(category.categoryId, {
            name: category.name,
            products: [item]
          });
        }
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.panel = Array.from(Array(groups.size).keys());
      return groups;
    }
  },
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async fetchList() {
      this.list = await ListService.getList(this.listId);
      // Analytic's viewProducts() uses a method that expects an item prop (for getting its price), so I need to spread that structure...
      const wishListProducts = [
        ...this.list.wishlistInactiveItems.map(wi => ({
          ...wi.product,
          product: wi.product
        })),
        ...this.list.wishlistItems.map(wi => ({
          ...wi.product,
          product: wi.product
        }))
      ];
      console.log("wishListProducts", wishListProducts);
      if (wishListProducts.length) {
        AnalyticsService.viewProducts(wishListProducts, "Wishlist", 0);
      }
    },
    setItemGroups(array) {
      let temp = groupBy(array, "product.categoryId");
      this.itemGroups = map(temp, products => {
        let category = this.$store.getters["category/lookupCategory"](
          products[0].product.categoryId
        ).name;
        return {
          category,
          products
        };
      });
      this.panel = Array.from(Array(this.itemGroups.length).keys());
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          this.list,
          itemId,
          product.productId
        );
        this.list = newList;
        this.setItemGroups(
          concat(this.list.wishlistInactiveItems, this.list.wishlistItems)
        );
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(this.listId);
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        this.listId,
        item,
        newQuantity
      );
      this.list = res;
    },
    reload() {
      this.fetchList();
    }
  },
  created() {
    this.reload();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
